import React, { useState, useEffect } from 'react';
import './SearchBar.css';
import { appConfig } from '../../../../../utils/app.config';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';


interface SearchBarProps {
    trendingSearches: string[];
    recentSearches: string[];
    searchedValue?: any
}

const SearchBar: React.FC<SearchBarProps> = ({ trendingSearches, recentSearches, searchedValue }) => {

    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [filteredRecentSearches, setFilteredRecentSearches] = useState(recentSearches);
    const [isListening, setIsListening] = useState(false);
    const redirectUrl: string = useSelector((state: any) => state.headers.redirectUrl);
    const navigate = useNavigate();

    useEffect(()=> {
        if(searchedValue){
            setSearchInput(searchedValue);
        }
        else {
            setSearchInput('')
        }
    }, [searchedValue]);


    // SpeechRecognition setup
    const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
    const recognition = new SpeechRecognition();

    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = 'en-US';

    const startListening = () => {
        recognition.start();
        setIsListening(true);
    };

    const stopListening = () => {
        recognition.stop();
        setIsListening(false);
    };

    recognition.onresult = (event: any) => {
        const transcript = event.results[0][0].transcript;
        console.log(event)
        console.log(transcript)
        setSearchInput(transcript);
        setFilteredRecentSearches(
            recentSearches.filter((search: any) => search['title'].toLowerCase().includes(transcript.toLowerCase()))
        );
        setIsListening(false);
    };

    recognition.onend = () => {
        setIsListening(false);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        console.log('value', e.target.value)
        setSearchInput(value);
        if (value) {
            const filtered = recentSearches.filter((search: any) =>
                search['title'].toLowerCase().includes(value.toLowerCase())
            );
            setFilteredRecentSearches(filtered);
        } else {
            setFilteredRecentSearches(recentSearches);
        }
    };

    const handleItemClick = (value: string) => {
        setSearchInput(value);
        console.log('Search saved:', value);
        setDropdownVisible(false);
    };

    const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log('Search submitted:', searchInput);
        navigate(`${redirectUrl}/desktop/kb-list`, { state: {passedData: searchInput} })
        setDropdownVisible(false);
    };

    const handleFocus = () => {
        setDropdownVisible(true);
        setFilteredRecentSearches(recentSearches);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setDropdownVisible(false); // delay for click on dropdown
        }, 200);
    };

    return (
        <div className="search-bar-container">
            <form onSubmit={handleSearchSubmit}>
                <div className={searchInput === searchedValue ? 'custom-search-bar-sub-container' : 'search-bar-sub-container'}>
                    <img  className={searchInput === searchedValue ? 'custom-searchbar-icon' : 'searchbar-icon'} src="/ui/static/img/desktop/Icon_feather_search.png" alt="search" />
                    <input
                        type="text"
                        value={searchInput}
                        onChange={handleSearchChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        placeholder="Search By"
                    />
                    <button
                        type="button"
                        className="voice-search-button"
                        onClick={isListening ? stopListening : startListening}
                        aria-label="Voice Search"
                    >
                        <img src={appConfig.imagesUrl + '/desktop/microphone.png'} alt="Voice Search" />
                    </button>
                </div>
            </form>
            {isDropdownVisible && (trendingSearches.length > 0 || filteredRecentSearches.length > 0) && (
                <div className="search-bar-dropdown" >
                    {filteredRecentSearches.length > 0 && (
                        <>
                            <div className="search-bar-dropdown-section">
                                <h4>Recent Searches</h4>
                                <ul>
                                    {filteredRecentSearches.map((search: any, index) => (
                                            <Link to={`${redirectUrl}/desktop/kb-list`} state={{passedData: search['title']}} style={{textDecoration: 'auto', color: 'black'}}>
                                        <li key={index} onClick={() => handleItemClick(search['title'])}>
                                            <img className='recentSearches' src={appConfig.imagesUrl + '/desktop/clock.svg'} />
                                            <div>{search['title']}</div>
                                        </li>
                                        </Link>
                                    ))}
                                </ul>
                            </div>
                        </>
                    )}
                    {trendingSearches.length > 0 && (
                        <div className="search-bar-dropdown-section">
                            <h4>Trending</h4>
                            <ul>
                                {trendingSearches.map((trend, index) => (
                                     <Link to={`${redirectUrl}/desktop/kb-list`} state={{passedData: trend}}  style={{textDecoration: 'auto', color: 'black'}}>
                                    <li key={index} onClick={() => handleItemClick(trend)}>
                                        <img className='trendingSearches' src={appConfig.imagesUrl + '/desktop/trending-up.png'} /> {trend}
                                    </li>
                                    </Link>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchBar;

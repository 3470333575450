import React, { useEffect, useState } from 'react';
import { Nav, Container, Form, Button, Row, Col, Card} from 'react-bootstrap';
import "./user-section.css";
import { useTheme } from '@mui/material';


const UserSection: React.FC = () => {

    let theme = useTheme();
    let color = theme.palette.mode;
    
    return (
        <div className={`container`}>
            <Card className='mt-3' text={color==="dark" ? 'white': 'dark'}>
                {/* add card-header class to below div for a bootstrap card header look */}
                <div className={`${color == 'dark' ? 'header-dark' : 'header-color'}`}>
                    <h5>User Information</h5>
                </div>
                <div className='card-body' style={{padding: '0px', marginTop: '8px'}}>
                    <div className='row'>
                         <div className='col-6 col-sm-4 col-xxl-3' >
                            <p className='card-details' style={{fontSize: '14px'}}>User ID<br/><strong style={{fontWeight: 600, fontSize: '16px'}}>Deepu Cyriac</strong></p>

                         </div>
                         <div className='col-6 col-sm-4 col-xxl-3'>
                            <p className='card-details'>User Name<br/><strong>Deepu Cyriac</strong></p>
                         </div>
                         <div className='col-6 col-sm-4 col-xxl-3'>
                            <p className='card-details'>Email<br/><strong>deepu.cyriac@tcs.com</strong></p>
                         </div>
                         <div className='col-6 col-sm-4 col-xxl-3'>
                         <p className='card-details'>Office Phone<br/><strong>8894323456</strong></p>
                         </div>
                         <div className='col-6 col-sm-4 col-xxl-3'>
                            <p className='card-details'>External Email<br/><strong>deepu.cyriac@tcs.com</strong></p>
                         </div>
                         <div className='col-6 col-sm-4 col-xxl-3'>
                         <p className='card-details'>Out of Office<br/><strong>18:00 PM</strong></p>
                         </div>
                         <div className='col-6 col-sm-4 col-xxl-3'>
                            <p className='card-details'>Mobile Phone<br/><strong>9934231211</strong></p>
                         </div>
                         <div className='col-6 col-sm-4 col-xxl-3'>
                         <p className='card-details'>Profile Create Date<br/><strong>21/3/24</strong></p>
                         </div>
                         <div className='col-6 col-sm-4 col-xxl-3'>
                            <p className='card-details'>Language<br/><strong>English</strong></p>
                         </div>
                    </div>
                    </div>
                </Card> 

                <Card className='mt-3' text={color==="dark" ? 'white': 'dark'}>
                    <div className={`${color == 'dark' ? 'header-dark' : 'header-color'}`}>
                        <h5>Location</h5>
                    </div>
                    <div className='card-body'  style={{padding: '0px', marginTop: '8px'}}>
                        <div className='row'>
                            <div className='col-6 col-sm-4 col-xxl-3'>
                                <p className='card-details'>Country<br/><strong>India</strong></p>
                            </div>
                            <div className='col-6 col-sm-4 col-xxl-3'>
                                <p className='card-details'>City<br/><strong>Kochi</strong></p>
                            </div>
                            <div className='col-6 col-sm-4 col-xxl-3'>
                                <p className='card-details'>Time Zone<br/><strong>IST</strong></p>
                            </div>
                            <div className='col-6 col-sm-4 col-xxl-3'>
                                <p className='card-details'>State<br/><strong>Kerala</strong></p>
                            </div>
                            <div className='col-6 col-sm-4 col-xxl-3'>
                                <p className='card-details'>Zipcode<br/><strong>586423</strong></p>
                            </div>
                        </div>
                    </div>
                </Card>

                <Card className='mt-3' text={color==="dark" ? 'white': 'dark'}>
                    <div className={`${color == 'dark' ? 'header-dark' : 'header-color'}`}>
                        <h5>Employment</h5>
                    </div>
                    <div className='card-body'  style={{padding: '0px', marginTop: '8px'}}>
                        <div className='row'>
                            <div className='col-6 col-sm-4 col-xxl-3'>
                                <p className='card-details'>Employment Type<br/><strong>Regular</strong></p>
                            </div>
                            <div className='col-6 col-sm-4 col-xxl-3'>
                                <p className='card-details'>Manager Name<br/><strong>gyan.prakash@tcs.com</strong></p>
                            </div>
                            <div className='col-6 col-sm-4 col-xxl-3'>
                                <p className='card-details'>Title<br/><strong>Administrator</strong></p>
                            </div>
                            <div className='col-6 col-sm-4 col-xxl-3'>
                                <p className='card-details'>Cost Center<br/><strong>--</strong></p>
                            </div>
                        </div>
                    </div>
                </Card>

                <Card className='mt-3 mb-3' text={color==="dark" ? 'white': 'dark'}>
                    <div className={`${color == 'dark' ? 'header-dark' : 'header-color'}`}>
                        <h5>User Account</h5>
                    </div>
                    <div className='card-body'  style={{padding: '0px', marginTop: '8px'}}>
                        <div className='row'>
                            <div className='col-6 col-sm-4 col-xxl-3'>
                                <p className='card-details'>Account Status<br/><strong>---</strong></p>
                            </div>
                            <div className='col-6 col-sm-4 col-xxl-3'>
                                <p className='card-details'>VIP Status<br/><strong>---</strong></p>
                            </div>
                            <div className='col-6 col-sm-4 col-xxl-3'>
                                <p className='card-details'>AD Groups Added<br/><strong>---</strong></p>
                            </div>
                            <div className='col-6 col-sm-4 col-xxl-3'>
                                <p className='card-details'>AD Sync Status<br/><strong>---</strong></p>
                            </div>
                            <div className='col-6 col-sm-4 col-xxl-3'>
                                <p className='card-details'>Lockout Status<br/><strong>---</strong></p>
                            </div>
                        </div>
                    </div>
                </Card>

            </div>
    )
}

export default UserSection;
import React, { useEffect, useRef, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import "./CustomTabs.css";

interface TabData {
  name: string;
  redirectUrl?: string;
  disabled?: boolean;
  content: React.ReactNode;
  progress?: number;
  loadingProgressBar?: boolean;
  badge?: string | number;
}

interface CustomTabsProps {
  data: TabData[];
}

const CustomTabs: React.FC<CustomTabsProps> = ({ data }) => {
  const [activeTab, setActiveTab] = useState<any>(0); // Default is none active
  const tabsRef = useRef<HTMLDivElement | null>(null); // Store refs to buttons
  const [isOverflow, setIsOverflow] = useState(false);
  const overflowRef = useRef(false);
  const buttonsRef = useRef<(HTMLDivElement | null)[]>([]); // Store refs to buttons
  const [indicatorStyle, setIndicatorStyle] = useState<React.CSSProperties>({}); // Style for the indicator

  const handleButtonClick = (tabName: string, index: number) => {
    console.log("tab content", tabName, index);

    setActiveTab(index); // Update the active tab
    const button = buttonsRef.current[index]; // Get the clicked button element
    console.log(buttonsRef.current[index]);
    if (button) {
      // Set the width and left position of the indicator
      setIndicatorStyle({
        width: `${button.offsetWidth}px`,
        left: `${button.offsetLeft}px`,
      });
    }
  };

  const checkOverflow = () => {
    if (tabsRef.current) {
      console.log(
        "called",
        tabsRef.current.scrollWidth,
        tabsRef.current.clientWidth,
        tabsRef.current.scrollWidth < 330
      );
      const clientWidth = tabsRef.current.clientWidth;
      const scrollWidth = tabsRef.current.scrollWidth;
      const isCurrentlyOverflowing = scrollWidth > clientWidth;

      console.log("scrolwwd", scrollWidth > clientWidth, isOverflow);
      // if(isCurrentlyOverflowing == true && isOverflow == false) {
      //   setIsOverflow(true)
      // }
      // else if(isCurrentlyOverflowing == false && isOverflow == true) {
      //   setIsOverflow(false);
      // }
      // if(scrollWidth > clientWidth !== isOverflow){
      //   console.log('inside if')
      //     setIsOverflow(scrollWidth > clientWidth);
      // }

      // if(overflowRef.current !== isCurrentlyOverflowing){
      //   overflowRef.current = isCurrentlyOverflowing;
      setIsOverflow(isCurrentlyOverflowing);

      // }
    }
  };

  useEffect(() => {
    // Initially set the indicator to the first button's position (if necessary)

    if (buttonsRef.current[0]) {
      setIndicatorStyle({
        width: `${buttonsRef.current[0]!.offsetWidth}px`,
        left: `${buttonsRef.current[0]!.offsetLeft}px`,
      });
    }
  }, []);

  const handleResize = () => {
    console.log("handle resize");
    checkOverflow();
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    checkOverflow();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollTabs = (direction: "left" | "right") => {
    console.log("working", tabsRef);

    if (tabsRef.current) {
      const scrollAmount = 100;
      if (direction === "left") {
        tabsRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
      } else {
        tabsRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
      }
    }
  };

  return (
    <>
      <div className="desktop-main-content">
        {isOverflow && (
          <button className="nav-btn" onClick={() => scrollTabs("left")}>
            &#9664;
          </button>
        )}
        <div
          className="desktop-main-content-display"
          ref={(node) => {
            tabsRef.current = node;
          }}
        >
          {data.map((tab, index) => (
            <>
            {tab.name != '' && (  <div
                className={`${
                  tab.disabled ? "disabledTabs" : ""
                } desktop-main-content-buttons`}
                ref={(el) => (buttonsRef.current[index] = el)}
                onClick={() => handleButtonClick(tab.name, index)}
              >
                {tab.name}
                {tab.badge !== undefined && (
                  <span
                    style={{
                      marginLeft: "6px",
                      display: "flex",
                      justifyContent: "center",
                      gap: "6px",
                      minWidth: "24px",
                      alignItems: "center",
                    }}
                    className={`badge rounded-pill bg-${
                      tab.name.toLowerCase() === "closed"
                        ? "secondary"
                        : "primary"
                    }`}
                  >
                    {tab.badge}
                  </span>
                )}
              </div>)}
            
            </>
          ))}
          <div className="horizontal-indicator" style={indicatorStyle}></div>

          {/* <div className='desktop-main-content-buttons' ref={(el) => (buttonsRef.current[0] = el)} onClick={() => handleButtonClick('Apps', 0)}>Apps</div>
                    <div className='desktop-main-content-buttons' ref={(el) => (buttonsRef.current[1] = el)} onClick={() => handleButtonClick('Outages', 1)}>List of Outages</div>
                    <div className='desktop-main-content-buttons' ref={(el) => (buttonsRef.current[2] = el)} onClick={() => handleButtonClick('Queries', 2)}>Top 10 Queries</div> */}
          {/* Horizontal indicator below the buttons */}
        </div>
        <>
          {data.map((tab) => {
            console.log('tab progress', tab.progress, tab.loadingProgressBar);
            return <>
              {tab.progress && tab.loadingProgressBar && (<div style={{width: '100%'}}>
                <ProgressBar
                  style={{ height: "6px" }}
                  animated
                  striped
                  now={tab.progress}
                ></ProgressBar>
              </div>)}
          
            
            </>
            
          }
            
            
          )}
        </>
       

        {isOverflow && (
          <button className="nav-btn" onClick={() => scrollTabs("right")}>
            &#9654;
          </button>
        )}
      </div>

    
      {/* Conditional rendering based on the active tab */}
      <div className="content-display">
        {data.map((tab, index) =>
          index === activeTab ? (
            <div key={index}>
              {tab.content ? (
                typeof tab.content === "string" ? (
                  <div>{tab.content}</div>
                ) : (
                  tab.content
                )
              ) : (
                <div></div>
              )}
            </div>
          ) : null
        )}

        {/* {activeTab === 'Apps' && (
                    <div>
                        <div className='display_apps'>
                            <AppsList redirectUrl={redirectUrl} />
                        </div>
                        <div className='catalog-content-display'>
                            <Catalog />
                        </div>
                    </div>
                )}

                {activeTab === 'Outages' && (
                    <div className='catalog-content-display'>
                        <Outages />
                    </div>
                )}

                {activeTab === 'Queries' && (
                    <div className='catalog-content-display'>
                        <Queries />
                    </div>
                )} */}
      </div>
    </>
  );
};

export default CustomTabs;

import React, { useEffect, useState } from "react";
import "./net-app-dashboard.css";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Tabs,
  Tab,
  Card,
  Table,
  Dropdown,
  Spinner,
  Nav,
  Alert,
} from "react-bootstrap";
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { appConfig } from "../../../utils/app.config";
import { getgetNetAppReportFullDetailsURL } from "../desktop-app/utils/constants";
import { useTheme } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Legend,
  Tooltip
);

const NetAppDashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [search, setSearch] = useState("");
  const [filterColumn, setFilterColumn] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const dashboardClick = () => setActiveTab("dashboard");
  const reportClick = () => setActiveTab("reports");
  let theme = useTheme();
  let color = theme.palette.mode;

  useEffect(() => {
    getNetAppReportFullDetails();
  }, []);

  const getNetAppReportFullDetails = async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const result = await (
        await fetch(getgetNetAppReportFullDetailsURL, options)
      ).json();
      if (result) {
        console.log("results", result);
        setData(result)
      }
    } catch (err: any) {
      console.log("ERROR :::", err);
      // setError(err instanceof Error ? err.message : 'An Error occured' )
      // setTopKbs([])
    }
    // setTimeout(() => setLoading(false), 200);
  };

  const headers = data.length > 0 ? Object.keys(data[0]) : [];
  console.log('headers', headers)

  const filteredData = data.filter((item) => {
    if(filterColumn && search){
      return item[filterColumn].toString().toLowerCase().includes(search.toLowerCase());
    }
    return true;
  });
console.log(filteredData);

const totalPages = Math.ceil(filteredData.length / itemsPerPage);
const currentData = filteredData.slice(
  (currentPage - 1) * itemsPerPage, currentPage * itemsPerPage
  );
  console.log(currentData);

  const handleNextPage = () => {
    if(currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if(currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleItemsPerPageChange = (e: any) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  const barData = {
    labels: [
      "passed",
      "Incident Context",
      "Catalogue",
      "SR Similar",
      "Live Apps",
    ],
    datasets: [
      {
        label: "Use Cases",
        data: [17, 0, 0, 0, 0],
        backgroundColor: [
          "#007bff",
          "#6c757d",
          "#28a745",
          "#ffc107",
          "#dc3545",
        ],
      },
    ],
  };

  const pieData = {
    labels: ["Successful", "Unsuccessful"],
    datasets: [
      {
        data: [16, 1],
        backgroundColor: ["#007bff", "#dc3545"],
      },
    ],
  };

  const barDataLastUseCase = {
    labels: [
      "Self Help",
      "Incident Status",
      "Live Agent",
      "SR Status",
      "Catalogue",
      "Incident Update",
    ],
    datasets: [
      {
        data: [16, 1, 0, 0, 0, 0],
        backgroundColor: ["#005bff"],
      },
    ],
  };

  const barOptionsLastUseCase = {
    indexAxis: "y" as const,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
    },
  };

  const barDataUseCaseRating = {
    labels: ["Excellent", "Average", "Not Helpful"],
    datasets: [
      {
        label: "Ratings",
        data: [5, 10, 2],
        backgroundColor: ["#007bff"],
      },
    ],
  };

  const pieDataUserSatisfaction = {
    labels: ["Excellent", "Average", "Not Helpful"],
    datasets: [
      {
        data: [20, 6, 1],
        backgroundColor: ["#007bff", "#dc3545"],
      },
    ],
  };

  return (
    <div className="App">
      <Container fluid>
        <Row className="align-items-center border-bottom">
          <Col sm={"auto"} className="text-start">
            <img
              style={{ height: "40px" }}
              src={appConfig.imagesUrl + "/logos/NetApp_logo.png"}
            />
          </Col>
          <Col sm={10}>
            <div className="justify-content-center navbar-class">
              <div
                className={`${
                  activeTab == "dashboard" ? "sel-nav-button" : "nav-button"
                }`}
                onClick={dashboardClick}
              >
                <i className="bi bi-columns-gap"></i>
                <p style={{ margin: 0 }}>Dashboard</p>
              </div>

              <div
                className={`${
                  activeTab == "reports" ? "sel-nav-button" : "nav-button"
                }`}
                onClick={reportClick}
              >
                <i className="bi bi-file-earmark-text-fill"></i>
                <p style={{ margin: 0 }}>Reports</p>
              </div>
            </div>
          </Col>

          {/* <Col md={3} className="text-end">
            <div></div>
          </Col> */}
        </Row>

        <Container fluid className="mt-4" style={{ margin: 0 }}>
          {activeTab === "dashboard" && (
            <div>
              <Row>
                <Col>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <Row>
                          <Col lg={5} style={{ padding: 12 }}>
                            <Form.Group>
                              <Form.Label>Start Date:</Form.Label>
                              <Form.Control
                                placeholder="Start Date:"
                                type="date"
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={5} style={{ padding: 12 }}>
                            <Form.Group>
                              <Form.Label>End Date:</Form.Label>
                              <Form.Control type="date" />
                            </Form.Group>
                          </Col>
                          <Col
                            lg={"auto"}
                            style={{
                              padding: 12,
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button>Get Report</Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col style={{ padding: 12 }}>
                            <Card
                              className={`${
                                color == "dark" ? "" : "text-bg-primary"
                              }`}
                              style={{
                                padding: 16,
                                height: "100%",
                                justifyContent: "space-between",
                                minWidth: "130px",
                              }}
                            >
                              <h5>Total Interaction with Bot:</h5>
                              <p style={{ margin: 0 }}>25</p>
                            </Card>
                          </Col>
                          <Col style={{ padding: 12 }}>
                            <Card
                              className={`${
                                color == "dark" ? "" : "text-bg-primary"
                              }`}
                              style={{
                                padding: 16,
                                height: "100%",
                                justifyContent: "space-between",
                                minWidth: "130px",
                              }}
                            >
                              <h5>Total Detections:</h5>
                              <p style={{ margin: 0 }}>18</p>
                            </Card>
                          </Col>
                          <Col style={{ padding: 12 }}>
                            <Card
                              className={`${
                                color == "dark" ? "" : "text-bg-primary"
                              }`}
                              style={{
                                padding: 16,
                                height: "100%",
                                justifyContent: "space-between",
                                minWidth: "130px",
                              }}
                            >
                              <h5>Unique Issues Couny:</h5>
                              <p style={{ margin: 0 }}>3</p>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>

              <Row style={{ marginTop: 12 }}>
                <Col md={12} style={{ padding: 12 }}>
                  <Card style={{ height: "100%" }} className="p-3">
                    <h5>Use Cases</h5>
                    <Bar data={barData} />
                  </Card>
                </Col>
                <Col md={4} style={{ padding: 12 }}>
                  <Card style={{ height: "100%" }} className="p-3">
                    <h5> KB Helpful </h5>
                    <Pie data={pieData} />
                  </Card>
                </Col>
                <Col md={8} style={{ padding: 12 }}>
                  <Card style={{ height: "100%" }} className="p-3">
                    <h5> Last Usecase Used </h5>
                    <Bar
                      data={barDataLastUseCase}
                      options={barOptionsLastUseCase}
                    />
                  </Card>
                </Col>
                <Col md={8} style={{ padding: 12 }}>
                  <Card style={{ height: "100%" }} className="p-3">
                    <h5> Use Case Based Rating </h5>
                    <Bar data={barDataUseCaseRating} />
                  </Card>
                </Col>
                <Col md={4} style={{ padding: 12 }}>
                  <Card style={{ height: "100%" }} className="p-3">
                    <h5> User Satisfaction </h5>
                    <Pie data={pieDataUserSatisfaction} />
                  </Card>
                </Col>
              </Row>
            </div>
          )}

          {activeTab === "reports" && (
            <div>
              <Row>
                <Col>
                  <Form>
                    <Row>
                      <Col>
                        <Row>
                          <Col sm={4} style={{ padding: 12 }}>
                            <Form.Group>
                              <Form.Label>Start Date:</Form.Label>
                              <Form.Control
                                placeholder="Start Date:"
                                type="date"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={4} style={{ padding: 12 }}>
                            <Form.Group>
                              <Form.Label>End Date:</Form.Label>
                              <Form.Control type="date" />
                            </Form.Group>
                          </Col>
                          <Col
                            sm={"auto"}
                            style={{
                              padding: 12,
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            <Button>Get Report</Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <h5>Contact Level Reporting</h5>
                    <div className=" mt-4">
                      {loading && (
                        <div className="text-center">
                          <Spinner animation="border" role="status">
                            <span className="visually-hiddden">Loading...</span>
                          </Spinner>
                        </div>
                      )}

                      {error && <Alert variant="danger">Error: {error}</Alert>}

                      {!loading && !error && (
                        <>
                          <div className="d-flex align-items-center mb-3">
                            <Dropdown className="me-2">
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                {filterColumn || "Select Column"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {headers?.map((header) => (
                                  <Dropdown.Item
                                    key={header}
                                    onClick={() => setFilterColumn(header)}
                                  >
                                    {header.charAt(0).toUpperCase() +
                                      header.slice(1)}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                            <Form.Control
                              type="text"
                              placeholder={`Search by ${
                                filterColumn || 'column'
                              }`}
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              disabled={!filterColumn}
                            />
                          </div>

                          <div className="mb-3">
                            <Form.Label>Records Per Page</Form.Label>
                            <Form.Select
                              value={itemsPerPage}
                              onChange={handleItemsPerPageChange}
                              className="w-auto"
                            >
                              <option value={5}></option>
                              <option value={10}></option>
                              <option value={15}></option>
                            </Form.Select>
                          </div>

                          <div style={{overflow: 'auto', height: '500px'}}>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                {headers?.map((header) => (
                                  <th style={{width: 'fit-content'}} key={header}>{header}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {currentData.length > 0 ? (
                                currentData.map((item, index) => 
                                (
                                  <tr key={index}>
                                    {headers?.map((header) => 
                                      <td key={header}>{item.hasOwnProperty(header) ?typeof item[header] === 'object' ? JSON.stringify(item[header]) : item[header]: 'NA'}</td>
                                    )}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan={headers?.length}
                                    className="text-center"
                                  >
                                    No data found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                          </div>

                          

                          <div className="d-flex justify-content-between align-items-center mt-3">
                            <button
                              className="btn btn-primary"
                              onClick={handlePreviousPage}
                              disabled={currentPage === 1}
                            >
                              Prev
                            </button>
                            <span>
                              Page {currentPage} of {totalPages}
                            </span>
                            <button
                              className="btn btn-primary"
                              onClick={handleNextPage}
                              disabled={currentPage === totalPages}
                            >
                              Next
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </Container>
    </div>
  );
};

export default NetAppDashboard;

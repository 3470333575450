import React from 'react';
import './DesktopNotifications.css';
import { useSelector } from 'react-redux';
import BackButton from '../../../../../../application/commons/back-button/back-button';
import { useTheme } from '@mui/material/styles'; // Import useTheme from MUI


const DesktopNotifications: React.FC = () => {
    const theme = useTheme();
    const notifications: string[] = useSelector((store: any) => store.notifications.notifications);

    return (
        <div className={`notification-container ${theme.palette.mode}`}>
            <BackButton />
            <div className="notification-container">
                {
                    notifications.length === 0 ? (
                        <div className="no-notifications">No notifications available.</div>
                    ) : (
                        <>
                            {notifications.length !== 0 && < h1 className='notification-heading'>Notifications</h1 >}

                            {notifications.map((notification, index) => (
                                <div key={index} className="notification-card hover_notification_card">
                                    <img src="/ui/static/img/desktop/logo_bot.PNG" alt="Tickets" />
                                    <p>{notification}</p>
                                </div>
                            ))}
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default DesktopNotifications;

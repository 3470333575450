import React, { useEffect, useState } from 'react';
import { Nav, Container, Form, Button, Row, Col, InputGroup, Card} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import "./support-section.css";
import Incidents from './components/Incidents/incidents';
import WorkOrders from './components/WorkOrders/workorders';
import RepeatIssueTickets from './components/RepeatIssueTickets/repeat-issue';
import { useTheme } from '@mui/material';



const SupportSection: React.FC = () => {

    let theme = useTheme();
    let color = theme.palette.mode;

    const [searchQuery, setSearchQuery] = useState('');
    const [kbSearchQuery, setKbSearchQuery] = useState('');

    const handleTicketSearch = (e: any) => {
        e.preventDefault();
    };

    const handleSearch = (e: any) => {
        e.preventDefault();
    };

    return (
        <Container >
            <Row>
                <Col style={{padding: 12}} lg={9}>
                    <Card  text={color==="dark" ? 'white': 'dark'}>
                            <div >
                                <Row className='align-items-center'>
                                    <Col md={3}>
                                        <Form
                                            onSubmit={handleTicketSearch}
                                        >
                                            <InputGroup style={{margin: '10px 0px'}}>
                                                <InputGroup.Text><i className="bi bi-search"></i></InputGroup.Text>
                                                <Form.Control
                                                size="sm"
                                                type="text"
                                                placeholder="Search Ticket"
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                                />
                                            </InputGroup> 
                                        </Form>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Select>
                                            <option>Select Issue</option>
                                            <option value="battery-issue">Battery Issue</option>
                                            <option value="ram-issue">RAM Issue</option>
                                            <option value="display issue">Display Issue</option>
                                        </Form.Select>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Select>
                                            <option>Select Device</option>
                                            <option value="laptop">Laptop</option>
                                            <option value="desktop">Desktop</option>
                                            <option value="mobile">Mobile</option>
                                        </Form.Select>
                                    </Col>
                                    <Col style={{display: 'flex', justifyContent: 'flex-end'}} md={'auto'}>
                                        <Button style={{width: 'fit-content'}} variant={`${color=='dark'? 'light' : 'outline-dark'}`}>Fix Now</Button>
                                    </Col>
                                </Row>
                                
                            </div>
                    </Card>
                    <Card className='mt-2' text={color==="dark" ? 'white': 'dark'}>
                            <h5 className={color==="dark" ? 'header-dark': 'header-font-color'}>Bot Interaction Summarization</h5>
                            <div style={{marginTop: '8px'}} >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </div>
                    </Card>
                    <div className= {`${color === 'dark' ? 'accordion-dark':'accordion-light'} mt-2`}>
                    <Accordion>
                    {/* defaultActiveKey="0" */}
                        <Accordion.Item eventKey="0" className='mt-3' style={{borderRadius: '8px', border: 'none'}}>
                            <Accordion.Header className='accordionHeader' style={{borderRadius: '8px 8px 0px 0px'}}><h5  style={{marginBottom: 0}}>Incidents</h5></Accordion.Header>
                            <Accordion.Body style={{borderRadius: '8px', marginTop: '8px', border: '1px solid #dee2e6'}}>
                                <Incidents />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className='mt-3' style={{borderRadius: '8px', border: 'none'}}>
                            <Accordion.Header className='accordionHeader'  style={{borderRadius: '8px 8px 0px 0px'}}><h5 style={{marginBottom: 0}}>WorkOrders</h5></Accordion.Header>
                            <Accordion.Body style={{borderRadius: '8px', marginTop: '8px', border: '1px solid #dee2e6'}}>
                                <WorkOrders />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className='mt-3' style={{borderRadius: '8px', border: 'none'}}>
                            <Accordion.Header className='accordionHeader' style={{borderRadius: '8px 8px 0px 0px'}}><h5  style={{marginBottom: 0}}> Repeat Issue Tickets</h5></Accordion.Header>
                            <Accordion.Body style={{borderRadius: '8px', marginTop: '8px', border: '1px solid #dee2e6'}}>
                                <RepeatIssueTickets />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    </div>
                    <Row className="mt-2">
                        <Col md={6}>
                        <Card className='mt-3' text={color==="dark" ? 'white': 'dark'}>
                            <h5 className={`${color === 'dark' ? 'header-color-dark' : 'header-font-color '}`}>
                                Survey Response
                            </h5>
                            <div style={{marginTop: '8px'}}>
                                <div className='row'>
                                    <div className='col'>
                                      <p>
                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                      </p>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        </Col>
                        <Col md={6}>
                        <Card className='mt-3' text={color==="dark" ? 'white': 'dark'}>
                            <h5 className={`${color === 'dark' ? 'header-color-dark' : 'header-font-color '}`}>
                                User Sentiments
                            </h5>
                            <div style={{marginTop: '8px'}}>
                                <div className='row'>
                                    <div className='col'>
                                      <p>
                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                      </p>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        </Col>
                    </Row>
                </Col>

                <Col style={{padding: 12}} lg={3} className='d-flex-align-items-start'>
                    <Card text={color==="dark" ? 'white': 'dark'}>
                            <h5 className={`${color === 'dark' ? 'header-color2-dark' : 'header-font-color'}`}>Knowledge Article</h5>
                            <div style={{marginTop: '8px'}}>
                            <Form
                                onSubmit={handleSearch}
                            >
                                <InputGroup>
                                <InputGroup.Text><i className="bi bi-search"></i></InputGroup.Text>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    placeholder="Type the article"
                                    value={kbSearchQuery}
                                    onChange={(e) => setKbSearchQuery(e.target.value)}
                                />
                                </InputGroup>
                            </Form>
                            <p>
                                {`You searched for: ${kbSearchQuery}`}
                            </p>
                            </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default SupportSection;
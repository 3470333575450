import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './error.css';

const Error404: React.FC = () => {
    return (
        <div className="container d-flex justify-content-center align-items-center vh-100">
            <div className="error-page text-center">
                <h1 className="display-4 text-danger">404 - Page not found</h1>
                <p className="lead">The page you're looking for doesn't exist.</p>
            </div>
        </div>
    );
};

export default Error404;

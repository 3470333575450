import React, { useEffect, useState } from 'react';
import { Nav, Card, Container, Form, Button, Row, Col} from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title } from 'chart.js';
import { useTheme } from '@mui/material';
import "./most-used-apps.css";

ChartJS.register(
    ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title
);



const MostUsedApps: React.FC = () => {

    let theme = useTheme();
    let color = theme.palette.mode;

    const data = {
        labels: ["SAP", "Teams", "Outlook", "Excel"],
        datasets: [
            {
                label: "undefined",
                barThickness: 5,
                data: [40, 35, 60, 30],
                backgroundColor: 'red',
            },
            {
                label: "undefined",
                barThickness: 5,
                data: [30, 25, 30, 50],
                backgroundColor: 'grey'
            },
        ],
    };

    const options: any = {
        indexAxis: "y",
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                beginAtZero: true,
                max: 100,
                stacked: true
            },
            y: {
                stacked: true
            },
        },
    };

    return (
        <Card>
            <h5 className={`${color == 'dark' ? 'header-dark' : ''}`}>Most Used Applications</h5>
            <Row>
                <Col>
                    <div className={`mt-3 ${color=="dark" ? 'text-white': 'text-dark'}`}>
                        <Bar data={data} options={options} width={'100%'} />
                    </div>
                </Col>
            </Row>
        </Card>
        
    )
}

export default MostUsedApps;
import React, { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import axios from 'axios';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js'; 
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import './Dashboard.css'; // Keep custom styles if needed
import '../../../../styles/global.css'; // Import global styles
import { appConfig } from '../../../../utils/app.config';

// Register scales, elements, and controllers for charts
ChartJS.register(
    CategoryScale,  
    LinearScale,
    BarElement,
    ArcElement,     
    Title,
    Tooltip,
    Legend
);

const Dashboard: React.FC = () => {
    const [barChartData, setBarChartData] = useState(null);
    const [pieChartData, setPieChartData] = useState(null);
    const apiUrl = appConfig.apiUrl + '/v2.0/dummy/charts';

    useEffect(() => {
        const fetchChartData = async () => {
            try {
                const response = await axios.get(apiUrl);
                setBarChartData(response.data.barChartData);
                setPieChartData(response.data.pieChartData);
            } catch (error) {
                console.error("Error fetching chart data:", error);
            }
        };

        fetchChartData();
    }, []);

    return (
        <div className="container" style={{padding: '20px'}}>
            <h2 className="text-center">Dashboard</h2>
            <div className="row">
                {/* Bar Chart Card */}
                <div className="col-md-6 mb-4">
                    <div className="card" style={{height: '100%'}}>
                        <div className="card-body" style={{padding: 0}}>
                            <h3 className="card-title">Bar Chart</h3>
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '85%'}}>
                            {barChartData && <Bar data={barChartData} options={{ responsive: true }} />}

                            </div>
                        </div>
                    </div>
                </div>

                {/* Pie Chart Card */}
                <div className="col-md-6 mb-4">
                    <div className="card">
                        <div className="card-body" style={{padding: 0}}>
                            <h3 className="card-title">Pie Chart</h3>
                            {pieChartData && <Pie data={pieChartData} options={{ responsive: true }} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;

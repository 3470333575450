import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { MenuLayout } from '../../application/layout/menu.layout';
import Dashboard from './dashboard/pages/Dashboard';
import BotProfiles from '../chat-multi/pages/BotProfiles';
import SomeComponent from '../../application/pages/SomeComponent';
import UserRoutes from './user/user.routes';
import { AdminRoutes } from './admin/admin.router';
import { GenAIStudioRoutes } from '../gen-ai-studio/genai.studio.router';
import { GenAIRoutes } from '../gen-ai/genai.router';
import PrivacyPolicy from '../../application/commons/privacy-policy/privacy-policy';
import Error404 from '../../application/pages/error404';

const CoreRoutes: React.FC = () => {
    const [menuOpen, setMenuOpen] = useState(true); // State to track menu visibility

    return (
        // <div className={`app ${menuOpen ? 'menu-open' : 'menu-closed'}`}>
        <div>
            <Routes>
                <Route path='/'>
                    <Route index element={<Dashboard />} />
                    <Route path="user/*" element={<UserRoutes />} />
                    <Route path="admin/*" element={<AdminRoutes />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="*" element={<Error404 />} />
                </Route>
            </Routes>
        </div>
    );
};

export default CoreRoutes;

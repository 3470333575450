import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './side-menu.css';
import { appConfig } from '../../../utils/app.config';
import { tokens } from '../../../theme';
import { useTheme } from '@mui/material';

interface MenuItem {
    id: number;
    name: string;
    icon?: string;
    path?: string;
    subMenu?: MenuItem[];
}

interface SideMenuProps {
    isOpen: boolean;
    toggleMenu: () => void;
}

const SideMenu: React.FC<SideMenuProps> = ({ isOpen, toggleMenu }) => {
    const theme = useTheme(); // Access the current theme
    const colors = tokens(theme.palette.mode);
    const textColor = `text-${colors.headers.text}`;

    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [openSubmenus, setOpenSubmenus] = useState<{ [key: number]: boolean }>({});
    const apiUrl = appConfig.apiUrl + '/v2.0/dummy/menu';
    useEffect(() => {
        // Fetch menu data from the local JSON server
        fetch(apiUrl)
            .then(response => response.json())
            .then(data => setMenuItems(data))
            .catch(error => console.error('Error fetching menu data:', error));
    }, []);

    const toggleSubmenu = (id: number) => {
        setOpenSubmenus(prevState => ({ ...prevState, [id]: !prevState[id] }));
    };
    useEffect(() => {
        // Set CSS variables based on the current theme colors
        document.documentElement.style.setProperty('--box-shadow', colors.headers.boxShadow);
    }, [theme]);

    const renderMenuItem = (menuItem: MenuItem) => {
        const isSubmenuOpen = openSubmenus[menuItem.id];

        return (
            <li key={menuItem.id} className="nav-item">
                {menuItem.subMenu ? (
                    <>
                        <button className={`nav-link btn ${textColor} w-100 ${isOpen ? 'text-start' : ''}`} onClick={() => toggleSubmenu(menuItem.id)}>
                            <i className={`bi-${menuItem.icon}`}></i>
                            <span>{isOpen && menuItem.name}</span>
                            {isOpen && (isSubmenuOpen ? <i className={`bi-caret-up ps-1`} > </i> : <i className={`bi-caret-down ps-1`}></i>)}
                        </button>
                        {isSubmenuOpen && isOpen && (
                            <ul className="submenu-list nav flex-column ms-3">
                                {menuItem.subMenu.map(subItem => renderMenuItem(subItem))}
                            </ul>
                        )}
                    </>
                ) : (
                    <Link to={menuItem.path || '#'} className={`nav-link ${textColor}`}>
                        <i className={`bi-${menuItem.icon}`}></i>
                        <span>{isOpen && menuItem.name}</span>
                    </Link>
                )}
            </li>
        );
    };

    return (
        <div className={`side-menu ${isOpen ? 'open' : 'closed'} text-white d-flex flex-column `} style={{ backgroundColor: colors.headers.backround }}>
            <div>
                <button className="menu-toggle btn w-100 text-start ms-2" onClick={toggleMenu}>
                    <i className={`bi-grid ${textColor}`}></i>
                </button>
            </div>

            <div style={{overflowY: 'auto'}}>
            <ul className="menu-list nav flex-column mt-2">
                {menuItems.map(menuItem => renderMenuItem(menuItem))}
            </ul>
            </div>

           
        </div>
    );
};

export default SideMenu;

import { appConfig } from "../../../../utils/app.config";


export const getRecentSearchUrl = appConfig.baseUrl + "/api/v2.0/desktop/app/recent/searches";
export const getTrendingSearchUrl = appConfig.baseUrl + "/api/v2.0/desktop/app/trending/searches";
export const getNotificationsUrl = appConfig.baseUrl + "/api/v2.0/desktop/app/notifications";
export const getDesktopAppsUrl = appConfig.baseUrl + "/api/v2.0/desktop/app/apps";
export const getTopIncidentsURL = appConfig.baseUrl + "/api/v2.0/desktop/app/topIncidents";
export const getTopCatalogsURL = appConfig.baseUrl + "/api/v2.0/desktop/app/topCatalogs";
export const getTopKbsURL = appConfig.baseUrl + "/api/v2.0/desktop/app/topKbs";
export const getTicketsCvaURL = appConfig.baseUrl + "/api/v2.0/desktop/app/ticketsCva";
export const getSearchKbURL = appConfig.baseUrl + "/api/v2.0/desktop/app/searchKb";
export const getgetNetAppReportFullDetailsURL = appConfig.baseUrl + "/api/v2.0/desktop/app/netAppReportFullDetails";
export const getAiGeneratedDataURL = 'https://cddgenaidev.azurewebsites.net' + "/api/cva/answer";
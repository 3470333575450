import React, { useEffect, useState } from "react";
import "./KbList.css";
import SearchBar from "../SearchBar/SearchBar";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { Box, Tab, Tabs, Card, Typography, CardContent, Collapse } from "@mui/material";
import { Link } from "react-router-dom";
import { getSearchKbURL, getAiGeneratedDataURL } from "../../utils/constants";
import BackButton from "../../../../../application/commons/back-button/back-button";
import CustomTabs from "../CustomTabs/CustomTabs";
import { appConfig } from '../../../../../utils/app.config';
import {ProgressBar} from 'react-bootstrap';

interface KbListProps {
  searchedResult: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  console.log();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const KbList: React.FC = () => {
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [searched, setSearched] = useState("");
  const [searchKB, setSearchKB] = useState([]);
  const [generatedData, setGeneratedData] = useState<any>([]);
  const [fixNow, setFixNow] = useState('');
  const [isExpanded, setIsExpanded] = useState(false); 
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const { passedData } = location.state || {};
  const recentSearches = useSelector(
    (store: any) => store.search.recentSearches
  );
  const trendingSearches = useSelector(
    (store: any) => store.search.trendingSearches
  );

  // const searchKB: any = useSelector((store: any) => store.kb.searchKb); const getSearchKb = async () => {

  const getSearchKb = async () => {
   
    // try {
    //   const options = {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       value: passedData,
    //     }),
    //   };
    //   const result = await (await fetch(getSearchKbURL, options)).json();
    //   if (result) {
       
    //     setSearchKB(result);
    //     // dispatch(updateSearchKb({ value: result }));
    //   }
    // } catch (err) {
    //   console.log("ERROR :::", err);
    //   setSearchKB([]);
    // }
  };

  console.log("searched Kb", searchKB);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  useEffect(() => {
    setSearched(passedData);
    passedData.length > 0 && getAiGeneratedData(passedData);

    // passedData.length > 0 && getSearchKb();
  }, [passedData]);
  console.log(searched);

  useEffect(() => {

    getAiGeneratedData(passedData);
    // const data =
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
    // setTimeout(() => setGeneratedData(data), 5000);
  }, []);

  useEffect(() => {
    let interval: any;

    if(isLoading){
      interval = setInterval(() => {
        setProgress((prev) =>  (prev >= 99 ? 99 : prev + 1));
      }, 100)
    }
    else {
      clearInterval(interval);
      setProgress(100);
    }

    return () => clearInterval(interval);
  }, [isLoading])

  const getAiGeneratedData = async (data: any) => {
    try {
      const options = {
        method: "POST",
      
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: data
        }),
      };
      const result = await (
        await fetch(getAiGeneratedDataURL, options)
      ).json();
      if (result) {
      //   const customResult = [
      //     {
      //         "Link": {},
      //         "Title": "Audio issue troubleshooting",
      //         "Steps": {
      //             "Step 1": "Check if your audio device is properly connected.",
      //             "Step 2": "Check if the audio device is enabled in your device's settings.",
      //             "Step 3": "Try using a different audio device to rule out any hardware issues.",
      //             "Step 4": "Update your device's audio drivers.",
      //             "Step 5": "Restart your device and check if the issue persists."
      //         }
      //     },
      //     {
      //         "Link": "https://tcsedev.service-now.com/api/now/v1/table/kb_knowledge?sysparm_query=number%3DKB0019182",
      //         "Title": "Audio Issue on Windows",
      //         "Steps": {
      //             "Step 1": "Check if the Speakers icon is showing Red cross icon, if yes, the issue is drivers related and we need to re-install drivers.",
      //             "Step 2": "Check if the Speaker level is muted.",
      //             "Step 3": "Check if the microphone level is muted.",
      //             "Step 4": "Re-install Audio Drivers.",
      //             "Step 5": "Restart the computer and check Audio."
      //         }
      //     },
      //     {
      //         "Link": "https://tcsedev.service-now.com/api/now/v1/table/kb_knowledge?sysparm_query=number%3DKB0019371",
      //         "Title": "How to adjust volume setting in your PC",
      //         "Steps": {
      //             "Step 1": "Right-click the Volume icon in the notification area (bottom-right corner) and Sounds.",
      //             "Step 2": "Select the playback device and click Set Default.",
      //             "Step 3": "If the device is disabled, right-click the device and select Enable and then click Set Default.",
      //             "Step 4": "Click the Volume icon in the notification area and set the volume to your preference.",
      //             "Step 5": "If your device is not listed in the playback devices window, right-click anywhere in the blank space and click 'Show Disabled Devices' and 'Show Disconnected Devices'."
      //         }
      //     },
      //     {
      //         "Link": "https://tcsedev.service-now.com/api/now/v1/table/kb_knowledge?sysparm_query=number%3DKB0019198",
      //         "Title": "Microphone is not working in Microsoft Teams",
      //         "Steps": {
      //             "Step 1": "Check for updates.",
      //             "Step 2": "Check if your microphone is in use.",
      //             "Step 3": "Make a test call.",
      //             "Step 4": "Troubleshooting for Windows.",
      //             "Step 5": "Troubleshooting for Teams on the web."
      //         }
      //     }
      // ]
    
     
      // setSearchKB(customResult)
      console.log("RESULT :::", JSON.parse(result.answer));

      if(result.nextthink){
        setFixNow(result.nextthink)
      }

        setGeneratedData(JSON.parse(result.answer))
      }
    } catch (err: any) {
      console.log("ERROR :::", err);
      // setError(err instanceof Error ? err.message : 'An Error occured' )
      // setTopKbs([])
    } finally {
      setIsLoading(false);
    }
    // setTimeout(() => setLoading(false), 200);
  };

  const [firstObject, ...restObjects] = generatedData;
  console.log(':::::::::::::', firstObject, restObjects)

  const triggerScript = (script: any)=>{
    console.log('script Name', script)
  }

  const tabData = [
    {
      name: "All",
      content: (
        <div
          style={{
            height: "calc(100vh - 172px)",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            overflowY: "auto",
          }}
        >
          About: {passedData}
          {generatedData.length > 0 ? (
            <>
              <Box
                style={{
                  padding: 16,
                  background: "rgb(255 221 232 / 29%)",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              ><Collapse in={isExpanded} collapsedSize='198px'>
                <Box sx={{maxHeight: isExpanded ? 'none': '198px', overflow: 'hidden', display: "flex", gap: "8px", flexDirection: 'column'}}>
                <div
                  style={{ display: "flex", gap: "8px", alignItems: "center", justifyContent: 'space-between' }}
                >
                  <div  style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                  <i
                    style={{ fontSize: "1.25rem", color: "#f98729" }}
                    className="bi bi-stars"
                  ></i>
                  <h5 className="h5-heading" style={{ margin: 0 }}>
                    AI Overview
                  </h5>

                  </div>
                 
                  {fixNow && (<button className='btn btn-primary' onClick={() => triggerScript(fixNow)} style={{width: 'fit-content',background: 'rgb(240 72 112)',border: 'none', borderRadius: '50px', fontWeight: 600}}>Fix Now</button>)}
                </div>
                <div style={{ minHeight: "70px", maxHeight: "fit-content" }}>
                  {firstObject ? (
                    <div style={{ margin: 0 }}>
                      <div style={{display: 'flex', gap: '10px', marginBottom: '8px'}}><p style={{ margin: 0, fontWeight: 600 }}>{firstObject.Title}</p><a style={{padding: '0px 10px', background: '#fde4ec',borderRadius: '50px'}} href={firstObject.Link} target='_blank'><i className="bi bi-link-45deg"></i></a></div>
                      <ul>
                        {Object.entries(firstObject.Steps).map(([key, value]) => (
                          <li style={{marginBottom: '4px'}} key={key}>
                            {typeof value === 'string'? value: ''}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <p
                      style={{ margin: 0 }}
                      className="placeholder-glow"
                    >
                                            <span className="placeholder col-12"></span>
                                            <span className="placeholder col-9"></span>

                      <span className="placeholder col-12"></span>
                      <span className="placeholder col-4"></span>
                    </p>
                  )}
                </div>
                </Box>
              </Collapse>
                
                <button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 'fit-content',
                    borderRadius: '50px',
                    marginTop: '8px',
                    border: '1px solid #f04870',
                    color: '#f04870'
                  }}
                  className="btn btn-outline-secondary show-more-btn"

                  onClick={() => setIsExpanded((prev) => !prev)}
                >
                  <i
                    style={{ fontSize: 16 }}
                    className="bi bi-arrow-down-short"
                  ></i>
                  <p style={{ margin: 0, fontSize: 12, fontWeight: 600 }}>
                    {isExpanded ? 'Show Less': 'Show More'}
                  </p>
                </button>
              </Box>
            
              {restObjects.map((item: any) => (
                <>
                  {item && item.isKb === false ? (
                    <>
                      <div
                        className="card shadow-sm"
                        style={{
                          overflow: "visible",
                          padding: "16px",
                          cursor: "pointer",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              margin: 0,
                              fontSize: "14px",
                              fontWeight: 500,
                              color: "var(--header-text-color)",
                            }}
                          >
                            {item.title}
                          </p>
                          <p
                            style={{
                              fontSize: "14px",
                              margin: "0px",
                              color: "#a9a9a9",
                            }}
                          >
                            Service Catalog
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Link
                        to="/mn/desktop/kb-article"
                        state={{ passedKbArticle: item }}
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className="card shadow-sm"
                          style={{
                            overflow: "visible",
                            padding: "16px",
                            cursor: "pointer",
                          }}
                        >
                          <div>
                            <p
                              style={{
                                margin: 0,
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "var(--header-text-color)",
                              }}
                            >
                              {item.Title}
                            </p>
                            {/* <p
                              style={{
                                fontSize: "14px",
                                color: "var(--header-text-color)",
                              }}
                            >
                              {item.subtitles}
                            </p> */}
                            {/* <p
                              style={{
                                fontSize: "14px",
                                color: "var(--header-text-color)",
                              }}
                            >
                              {item.publishDate} • {item.author}
                            </p> */}
                          </div>
                        </div>
                      </Link>
                    </>
                  )}
                </>
              ))}
            </>
          ) : (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
               <div className="loading-pop-up-icon">
                        <img src={appConfig.imagesUrl + '/desktop/loading.gif'}></img>
                    </div>
            </div>
          )}
        </div>
      ),
     
    },
    {
      name: "How to?",
      content: "",
      disabled: true,
    },
    {
      name: "Incidents",
      content: "",
      disabled: true,
    },
    {
      name: "Service Requests",
      content: "",
      disabled: true,
    },
    {
      name: '',
      content: '',
      progress: progress,
      loadingProgressBar: isLoading
    }
  ];

  return (
    <>
      <div className="row1">
        <div id="back" className="back">
          <BackButton></BackButton>
        </div>
        <div className="logo_bot" style={{ flex: "1 0 auto" }}>
          <div className="bot_image"></div>
        </div>
        <div className="searchBar" style={{ width: "100%" }}>
          <SearchBar
            trendingSearches={trendingSearches}
            recentSearches={recentSearches}
            searchedValue={searched}
          ></SearchBar>
        </div>
      </div>
      <div className="kbListDiv">
        <CustomTabs data={tabData}/>

        {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            aria-label="basic tabs example"
          >
            <Tab label="All" {...a11yProps(0)} />
            <Tab label="How to?" disabled {...a11yProps(1)} />
            <Tab label="Incidents" disabled {...a11yProps(2)} />
            <Tab label="Service Requests" disabled {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <div
            style={{
              height: "calc(100vh - 172px)",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              overflowY: "auto",
            }}
          >
            About: {passedData}
            {searchKB.length > 0 ? (
              <>
                {searchKB.map((item: any) => (
                  <>
                    {item && item.isKb === false ? (
                      <>
                        <Card sx={{ overflow: "visible", cursor: "pointer" }}>
                          <CardContent>
                            <Typography variant="h6">{item.title}</Typography>
                            <Typography
                              variant="body1"
                              style={{ fontSize: "14px", color: "#a9a9a9" }}
                            >
                              Service Catalog
                            </Typography>
                          </CardContent>
                        </Card>
                      </>
                    ) : (
                      <>
                        <Link
                          to="/mn/desktop/kb-article"
                          state={{passedKbArticle: item}}
                          style={{ textDecoration: "none" }}
                        >
                          <Card sx={{ overflow: "visible", cursor: "pointer" }}>
                            <CardContent>
                              <Typography variant="h6">{item.title}</Typography>
                              <Typography
                                variant="body1"
                                style={{ fontSize: "14px", color: "#a9a9a9" }}
                              >
                                {item.subtitles}
                              </Typography>
                              <Typography
                                variant="body1"
                                style={{ fontSize: "14px" }}
                              >
                                {item.publishDate} • {item.author}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Link>
                      </>
                    )}
                  </>
                ))}
              </>
            ) : (
              <div>No Search Kb</div>
            )}
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}></CustomTabPanel>
        <CustomTabPanel value={value} index={2}></CustomTabPanel>
        <CustomTabPanel value={value} index={3}></CustomTabPanel> */}
      </div>
    </>
  );
};

export default KbList;

import React, { useEffect, useState } from 'react';
import { Nav, Card, Container, Form, Button, Row, Col, ProgressBar} from 'react-bootstrap';

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title } from 'chart.js';
import "./overall-exp.css";
import { useTheme } from '@mui/material';

ChartJS.register(
    ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title
);

const OverallExp: React.FC = () => {

    let theme = useTheme();
    let color = theme.palette.mode;

    const lineChartData = {
        labels: ["Jan", "Feb", "Mar","Apr", "May","Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                label: "undefined",
                data: [3,4,5,3,4,5,6,7,8,2,1,4],
                borderColor: 'grey',
                backgroundColor: 'grey',
                fill: true,
                tension: 0.0,
            },
            {
                label: "undefined",
                data: [2,3,4,3,5,4,5,6,7,1,2,5],
                borderColor: 'red',
                backgroundColor: 'red',
                fill: true,
                tension: 0.0,
            },
        ],
    };

    const lineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <Card>
            <h5 className={`${color == 'dark' ? 'header-dark' : ''}`}>Overall Experience and Trend</h5>
            <Row>
                <Col>
                    <div className={`mt-3 ${color==="dark" ? 'text-white': 'text-dark'}`}>
                        <Line data={lineChartData} options={lineOptions} />
                    </div>
                </Col>
            </Row>
        </Card>
        
    )
}

export default OverallExp;
import React, { useEffect, useState } from 'react';
import { Nav, Card, Table,  Container, Form, Button, Row, Col} from 'react-bootstrap';
import { useTheme } from '@mui/material';
import "./details.css";



const Details: React.FC = () => {

    let theme = useTheme();
    let color = theme.palette.mode;

    const details = [
        {
            item: "Shared Drives",
            value: "10.192.187.27"
        },
        {
            item: "Home Directory",
            value: "00-BF-48-77-11-88"
        },
        {
            item: "Access Levels",
            value: "TCS"
        }
    ]

    return (
        <Card style={{height: '100%'}} >
            <h5 className={`${color == 'dark' ? 'header-dark' : ''}`}>Details</h5>
            <div className='table-responsive' style={{tableLayout: 'fixed', width: '100%', wordWrap: 'break-word', marginTop: '20px'}}>
            <Table  bordered data-bs-theme={color}>
            <thead>
                <tr>
                    <th><div className={`${color == 'dark' ? 'table-header-dark' : 'table-header'}`}>Item</div></th>
                    <th><div className={`${color == 'dark' ? 'table-header-dark' : 'table-header'}`}>Value</div></th>
                </tr>
            </thead>
            <tbody>
                {details.map((detail, index) => (
                    <tr>
                        <td><span className="row-header">{detail.item}</span></td>
                        <td><span className='row-value'>{detail.value}</span></td>
                    </tr>
                ))}
            </tbody>
            </Table>
            </div>
           
        </Card>
    )
}

export default Details;


import ReactWebChat, { createDirectLine, type createStore } from 'botframework-webchat';
import  React, { memo, useEffect, useMemo } from 'react';
import { appConfig } from '../../../utils/app.config';
import './WebChat.css';

type Props = Readonly<{
  className?: string | undefined;
  onFetchToken?: (() => void) | undefined;
  store: ReturnType<typeof createStore>;
  styleOptions: any;
  styleSet: any;
  token: string;
}>;

function WebChat({
  className = "react-web-chat",
  onFetchToken = () => {},
  store = {} as ReturnType<typeof createStore>, // default empty store
  styleOptions = {},
  styleSet = {},
  token = ""
  }: Props) {
  console.log("----styleOptions:", styleOptions);

  let domain = `${appConfig.baseUrl}/gwt/channels/v1.0`
  const directLine = useMemo(() => createDirectLine(
    {
      token: token,
      domain: domain,
      webSocket: false,
      pollingInterval: 5000

    } as any), [token]);

  useEffect(() => {
    onFetchToken?.();
  }, [onFetchToken]);

  return token ? (
    <ReactWebChat 
      className={`${className || ''} web-chat`}
      directLine={directLine}
      store={store}
      styleOptions={styleOptions} 
      //styleSet={styleSet}
      />
  ) : (
    <div className={`${className || ''} connect-spinner`}>
      <div className="content">
        <div className="icon">
          <span className="ms-Icon ms-Icon--Robot" ></span> 
        </div>
        <p>{'Please wait while we are connecting.'}</p>
      </div>
    </div>
  );
}

export default memo(WebChat);

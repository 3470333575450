import React, { useEffect, useState } from "react";
import "./KbArticle.css";
import { useLocation } from "react-router";
import { Rating } from "@mui/material";
import BackButton from "../../../../../application/commons/back-button/back-button";

const KbArticle: React.FC = () => {
  const location = useLocation();

  const { passedKbArticle } = location.state || {};
  console.log("passed kb Article", passedKbArticle);

  return (
    <>
      <div className="kbArticleWrapper">
        <div className="row1">
          <div id="back" className="back">
            <BackButton></BackButton>
          </div>
          <h5 style={{ fontWeight: "600", marginBottom: '0px' }}>
            {passedKbArticle?.Title}
          </h5>
        </div>
        {/* <div style={{padding: '0px 16px'}}>
            <Rating value={Number(passedKbArticle?.rating)} readOnly />
            </div> */}
        <div style={{ margin: "0px 16px", marginTop: '8px' }} className="contentBox">
          <h5>Summary</h5>
          <div>
            {Object.entries(passedKbArticle.Steps).map(([key, value]) => (
              <li style={{ marginBottom: "4px" }} key={key}>
                {typeof value === "string" ? value : ""}
              </li>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default KbArticle;

import React, { useEffect, useState } from 'react';
import { Card, Nav, Container, Form, Button, Row, Col, CardFooter} from 'react-bootstrap';
import "./incidents.css";
import { useTheme } from '@mui/material';


const Incidents: React.FC = () => {

    let theme = useTheme();
    let color = theme.palette.mode;

    return (
        <div className={`scroll-container ${color==="dark" ? "dark-theme" : "light-theme"}`}>
            <Card className='main-card' text={color==="dark" ? 'white': 'dark'}>
                <div>
                    <h5 className={`${color === 'dark' ? 'card-header-dark' : 'card-header-light'}`}>INC04R2442</h5>
                    <Card.Text>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>test</strong><br/>---</p>
                            <p><strong>Opened</strong><br/>2024-10-21 02:03:12</p>
                            <p><strong>Status</strong><br/><span className='status-red'>New</span></p>
                            <p><strong>Work Notes</strong><br/>vfvfsvfdsvfsvdfsvfv gtrbstbsgrb bgbtrstbgstr</p>
                        </div>
                    </div>
                    </Card.Text>
                </div>
            </Card>
            <Card className='main-card' text={color==="dark" ? 'white': 'dark'}>
                <div>
                    <h5 className={`${color === 'dark' ? 'card-header-dark' : 'card-header-light'}`}>INC04R34344</h5>
                    <Card.Text>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>test</strong><br/>---</p>
                            <p><strong>Opened</strong><br/>2024-10-21 02:03:12</p>
                            <p><strong>Status</strong><br/><span className='status-red'>New</span></p>
                            <p><strong>Work Notes</strong><br/>vfvfsvfdsvfsvdfsvfv gtrbstbsgrb bgbtrstbgstr</p>
                        </div>
                    </div>
                    </Card.Text>
                </div>
            </Card>
            <Card className='main-card' text={color==="dark" ? 'white': 'dark'}>
                <div>
                    <h5 className={`${color === 'dark' ? 'card-header-dark' : 'card-header-light'}`}>INC04R545534</h5>
                    <Card.Text>
                        <div className='row'>
                        <div className='col'>
                            <p><strong>test</strong><br/>---</p>
                            <p><strong>Opened</strong><br/>2024-10-21 02:03:12</p>
                            <p><strong>Status</strong><br/><span className='status-red'>New</span></p>
                            <p><strong>Work Notes</strong><br/>vfvfsvfdsvfsvdfsvfv gtrbstbsgrb bgbtrstbgstr</p>
                        </div>
                    </div>
                    </Card.Text>
                </div>
            </Card>
            <Card className='main-card' text={color==="dark" ? 'white': 'dark'}>
                <div>
                    <h5 className={`${color === 'dark' ? 'card-header-dark' : 'card-header-light'}`}>INC0442342</h5>
                    <Card.Text>
                        <div className='row'>
                        <div className='col'>
                            <p><strong>test</strong><br/>---</p>
                            <p><strong>Opened</strong><br/>2024-10-21 02:03:12</p>
                            <p><strong>Status</strong><br/><span className='status-red'>New</span></p>
                            <p><strong>Work Notes</strong><br/>vfvfsvfdsvfsvdfsvfv gtrbstbsgrb bgbtrstbgstr</p>
                        </div>
                    </div>
                    </Card.Text>
                </div>
            </Card>
        </div>
    )
}

export default Incidents;